import { Dashboard } from "../dashboard/dashboard";
import { CellInfo, CellStyle, CellVariant, DashboardConfig, StylesByVariant } from "./configSchema";
import { updateDashboardConfig } from "./dashboard-config";

export const createCellStyle = <V extends CellStyle["variant"]>({
  id,
  variant,
  style,
}: {
  id: string;
  variant: V;
  style?: Partial<CellStyle>;
}) => {
  const newStyle: CellStyle = {
    id,
    variant,
    ...(style ?? {}),
  };
  return newStyle as StylesByVariant<V>;
};

export const addCellStyle = (db: Dashboard, style: CellStyle) => {
  const newStyles = {
    ...db.draftConfig.styles,
    [style.id]: { ...variantDefaults[style.variant], ...style },
  };
  return updateDashboardConfig(db, "styles", newStyles);
};

export const findStylesById = (db: Dashboard, id: string) => {
  return db.draftConfig.styles[id];
};

export const setCellStyles = <V extends CellVariant>(
  db: Dashboard,
  cellId: string,
  newStyle: Partial<StylesByVariant<V>>,
): Dashboard => {
  const styles = findStylesById(db, cellId) as StylesByVariant<V>;
  const newStyles = {
    ...db.draftConfig.styles,
    [cellId]: {
      ...styles,
      ...newStyle,
    },
  };
  return updateDashboardConfig(db, "styles", newStyles as DashboardConfig["styles"]);
};

export const removeCellStyles = (db: Dashboard, cellId: string): Dashboard => {
  // Check if the config exists for the cellId
  if (!findStylesById(db, cellId)) {
    return db; // Config does not exist, nothing to do
  }

  // Create a new configs object without the specified cellId
  const newStyles = { ...db.draftConfig.styles };
  delete newStyles[cellId];

  // Update the dashboard configuration
  return updateDashboardConfig(db, "styles", newStyles);
};

export const isCellStyleVariant = <V extends CellStyle["variant"]>(
  style: CellStyle,
  variant: V,
): style is StylesByVariant<V> => {
  return style.variant === variant;
};

const variantDefaults: { [key in CellInfo["variant"]]?: Partial<CellStyle> } = {
  params: {
    vertAlignKey: "start",
    horizAlignKey: "start",
  },
};
