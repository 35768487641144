import { Dashboard } from "../dashboard/dashboard";
import { findCellById } from "./cells";
import { CellInfo, DashboardView } from "./configSchema";
import { type CellLayout } from "./configSchema";
import { updateDashboardConfig } from "./dashboard-config";

export const createCellLayout = ({
  id,
  layout,
  variant,
  db,
  parentId,
}: {
  id: string;
  layout?: Partial<CellLayout>;
  variant: CellInfo["variant"];
  db: Dashboard;
  parentId: string;
}) => {
  const defaultSize = variantDefaults[variant] ?? { w: 6, h: 6 };
  // make tab-layout fill the parent
  if (variant === "tab-layout") {
    const parentLayout = findLayoutById(db, parentId);
    const parentHeight = parentLayout?.h;
    const newLayout: CellLayout = { id, x: 0, y: 0, w: 12, h: parentHeight ?? 20 };
    return newLayout;
  }
  // Find the highest y + h value in the existing child layouts of the parent
  const childLayouts = getLayoutsFromCellId(db, parentId, "draft");
  const maxY = childLayouts.reduce((max, layout) => Math.max(max, (layout?.y ?? 0) + (layout?.h ?? 0)), 0);
  const newLayout: CellLayout = {
    id,
    x: layout?.x ?? 0,
    y: layout?.y ?? maxY,
    w: layout?.w ?? defaultSize.w,
    h: layout?.h ?? defaultSize.h,
  };

  return newLayout;
};

export const updateLayouts = (db: Dashboard, layouts: CellLayout[]) => {
  const currentLayouts = db.draftConfig.layouts;
  const newLayouts: Dashboard["draftConfig"]["layouts"] = layouts.reduce(
    (acc, item) => ({ ...acc, [item.id]: item }),
    {},
  );

  return updateDashboardConfig(db, "layouts", { ...currentLayouts, ...newLayouts });
};

export const addCellLayout = (db: Dashboard, layout: CellLayout): Dashboard => {
  // Create a new layouts object by copying all existing layouts and adding/updating the specified layout.
  const newLayouts: Dashboard["draftConfig"]["layouts"] = {
    ...db.draftConfig.layouts,
    [layout.id]: layout,
  };
  return updateDashboardConfig(db, "layouts", newLayouts);
};

export const removeCellLayout = (db: Dashboard, cellId: string): Dashboard => {
  const { layouts } = db.draftConfig;
  if (!findLayoutById(db, cellId)) {
    return db; // Layout does not exist, nothing to do
  }

  // Create a new layouts object without the specified cellId
  const newLayouts = { ...layouts };
  delete newLayouts[cellId];

  // Update the dashboard configuration
  return updateDashboardConfig(db, "layouts", newLayouts);
};

export const findLayoutById = (db: Dashboard, id: string, dashboardView: DashboardView = "draft") => {
  if (dashboardView === "published") {
    return db.publishedConfig?.layouts[id];
  }
  return db.draftConfig.layouts[id];
};

export const getLayoutsFromCellId = (db: Dashboard, cellId: string, dashboardView: DashboardView) => {
  const cell = findCellById(db, cellId, dashboardView);
  const childIds = cell?.childCellIds ?? [];
  const layouts = childIds.map((id) => findLayoutById(db, id)).filter((layout) => layout !== undefined);
  return layouts;
};

export const updateCellLayout = (db: Dashboard, cellId: string, layout: Partial<CellLayout>): Dashboard => {
  const currentLayout = db.draftConfig.layouts[cellId];
  if (!currentLayout) return db;

  const newLayout = { ...currentLayout, ...layout };
  return updateDashboardConfig(db, "layouts", { ...db.draftConfig.layouts, [cellId]: newLayout });
};

export const toggleCellLocked = (db: Dashboard, cellId: string): Dashboard => {
  const currentLayout = db.draftConfig.layouts[cellId];
  if (!currentLayout) return db;

  const newLayout = { ...currentLayout, static: !currentLayout.static };
  return updateDashboardConfig(db, "layouts", { ...db.draftConfig.layouts, [cellId]: newLayout });
};

const variantDefaults: { [key in CellInfo["variant"]]?: Pick<CellLayout, "w" | "h"> } = {
  visualization: {
    w: 12,
    h: 20,
  },
  params: {
    w: 12,
    h: 4,
  },
  layout: {
    w: 12,
    h: 20,
  },
  "tabs-panel": {
    w: 12,
    h: 30,
  },
  text: {
    w: 12,
    h: 20,
  },
};
