import { BehaviorSubject, Subject, Subscription } from "rxjs";

// Define the NodeData interface with parentId and isOpen

/**
 * Represents the base structure of a row of data in the tree.
 * @typedef {Object} BaseRowData
 * @property {string} id - Unique identifier for the row.
 * @property {string} name - Display name of the row.
 * @property {string|null} [parentId] - Identifier of the parent row. Optional.
 * @property {boolean} [isLeaf] - Indicates if the row is a leaf (has no children). Optional.
 */
export type BaseRowData = {
  id: string;
  name: string;
  parentId?: string | null;
  isLeaf?: boolean;
  isOpen?: boolean;
};

/**
 * Extends BaseRowData with additional properties related to the tree structure.
 * @typedef {Object} FullData
 * @template T - The base row data structure.
 * @extends {T}
 * @property {boolean} [isOpen] - Indicates if the tree node is expanded to show its children. Optional.
 * @property {number} [depth] - The level of depth of the node in the tree. Optional.
 * @property {boolean} [isSelected] - Indicates if the tree node is selected. Optional.
 */
export type FullData<T extends BaseRowData> = T & {
  isOpen?: boolean;
  depth?: number;
  isSelected?: boolean;
  isEditing?: boolean;
  isLeaf?: boolean;
  toggleEdit: () => void;
  setName: (name: string) => void;
  remove: () => void;
};

/**
 * Represents a single node within the tree structure.
 * @class TreeNode
 * @template T - The base row data structure.
 */
export class TreeNode<T extends BaseRowData> {
  constructor(
    public data: T,
    public tree: Tree<T>,
  ) {
    this.children = new Map();
    this.isOpen = data.isOpen ?? false;
    this.isSelected = false;
    this.hasFetched = false;
    this.isEditing = false;
    this.isLeaf = this.tree.leafDeterminationFunction(data);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.setName = this.setName.bind(this);
    this.remove = this.remove.bind(this);
  }

  children: Map<string, TreeNode<T>>; // Map of child nodes indexed by their ID.
  isOpen: boolean; // Indicates if the node is expanded to show its children.
  isSelected: boolean; // Indicates if the node is currently selected.
  hasFetched: boolean; // Indicates if the node's children have been fetched.
  isEditing: boolean; // Indicates if the node is being edited.
  isLeaf: boolean; // Indicates if the node is a leaf (has no children).

  toggleEdit(): void {
    this.tree.toggleEdit(this.data.id);
  }
  setName(name: string): void {
    this.tree.setName(this.data.id, name);
  }
  remove(): void {
    this.tree.remove(this.data.id);
  }
}

export type SortFunction<T extends BaseRowData> = (a: TreeNode<T>, b: TreeNode<T>) => number;
export type LeafDeterminationFunction<T extends BaseRowData> = (data: T) => boolean;
/**
 * A generic class representing a tree structure with nodes of type T.
 * @class Tree
 * @template T - The base row data structure that nodes in this tree should conform to.
 */
export class Tree<T extends BaseRowData> {
  private rootItems: TreeNode<T>[]; // Store root items as an array
  private nodeMap: Map<string, TreeNode<T>>;
  private flattenNodesSubject: BehaviorSubject<FullData<T>[]> = new BehaviorSubject<FullData<T>[]>([]);
  private treeEventsSubject = new Subject<TreeEvent<T>>();
  public flattenedTree: FullData<T>[];
  public flattenedTree$ = this.flattenNodesSubject.asObservable();
  public selectedNodeIds: Set<string>;
  public sortFunction?: SortFunction<T>;
  public leafDeterminationFunction: LeafDeterminationFunction<T>;
  public fetchItemsPromise?: (parentId: string | null, parentNode?: T) => Promise<T[]>;
  public isInitialLoading = false;

  constructor(
    initialItems?: T[],
    sortFunction?: SortFunction<T>,
    observer?: (event: TreeEvent<T>) => void,
    leafDeterminationFunction: LeafDeterminationFunction<T> = () => false,
    fetchItemsPromise?: (parentId: string | null, parent?: T) => Promise<T[]>,
  ) {
    this.rootItems = [];
    this.nodeMap = new Map<string, TreeNode<T>>();
    this.treeEventsSubject = new Subject<TreeEvent<T>>();
    this.flattenedTree = [];
    this.selectedNodeIds = new Set<string>();
    this.sortFunction = sortFunction;
    this.leafDeterminationFunction = leafDeterminationFunction;
    this.fetchItemsPromise = fetchItemsPromise;
    // If initialItems is provided, add them to the tree
    if (initialItems && initialItems.length > 0) {
      this.isInitialLoading = false;
      this.addMany(initialItems);
    }
    if (observer) {
      this.subscribeToEvents(observer);
    }
    this.treeEventsSubject.next({ type: "TREE_INITIALIZED", payload: this });

    // Fetch items if fetchItemsPromise is provided
    if (fetchItemsPromise) {
      this.fetchItems(null);
    }
  }

  async fetchItems(parentId: string | null, parent?: T): Promise<void> {
    // only fetch items if fetchItemsPromise is provided and we're in a browser environment
    if (!this.fetchItemsPromise || typeof document == "undefined") {
      return;
    }
    const parentNode = parentId ? this.get(parentId) : null;
    if (parentNode && parentNode.hasFetched) {
      // If the parent node's children have already been fetched, don't fetch items
      return;
    }

    try {
      // Add loading state to the parent node
      if (parentId) {
        this.toggleLoading(parentId);
      }

      // Create a promise that resolves after a minimum of 300ms
      const minimumDelayPromise = new Promise((resolve) => setTimeout(resolve, 150));

      // Wait for both the fetch operation and the minimum delay to complete
      const [items] = await Promise.all([this.fetchItemsPromise(parentId, parent), minimumDelayPromise]);

      if (this.isInitialLoading) {
        this.isInitialLoading = false;
      }
      // Remove loading state from the parent node
      if (parentId) {
        this.toggleLoading(parentId);
      }
      this.treeEventsSubject.next({ type: "FETCHED_NEW_ITEMS", payload: { parentId, items } });
      this.addMany(items);

      // Mark the parent node as fetched
      if (parentNode) {
        parentNode.hasFetched = true;
      }
    } catch (error) {
      console.error(`Failed to fetch items for parent id ${parentId}:`, error);
      this.treeEventsSubject.next({
        type: "FETCH_ERROR",
        payload: { parentId, error: new Error("error fetching data") },
      });
      // Handle the error as needed

      // Remove loading state from the parent node in case of error
      if (parentId) {
        this.toggleLoading(parentId);
      }
    }
  }

  setSortFunction(sortFunction: SortFunction<T>): void {
    this.sortFunction = sortFunction;
    this.updateFlattenNodesAndSubject();
  }

  /**
   * Retrieves a TreeNode object based on the provided node ID.
   * @param {string} nodeId - The unique identifier of the node to retrieve.
   * @returns {TreeNode<T> | undefined} - The TreeNode object if found, otherwise undefined.
   */
  get(nodeId: string): TreeNode<T> | undefined {
    return this.nodeMap.get(nodeId);
  }

  /**
   * Adds a new node to the tree.
   * @param {T} data - The data associated with the node to add.
   * @param {boolean} [skipUpdate=false] - If true, the tree will not update the flattened structure immediately.
   * @returns {string | null} - The ID of the added node or null if the parent does not exist.
   */
  add(data: T, skipUpdate?: boolean): string | null {
    const nodeId = data.id;
    const parentNodeId = data.parentId ?? null;
    if (this.nodeMap.has(nodeId)) {
      this.nodeMap.set(nodeId, new TreeNode<T>(data, this));
      return nodeId;
    }
    if (parentNodeId !== null) {
      const parentNode = this.nodeMap.get(parentNodeId);
      if (!parentNode) {
        // throw new Error("Parent node does not exist.");
        return null;
      }
      const newNode = new TreeNode<T>(data, this);
      parentNode.children.set(nodeId, newNode);
      this.nodeMap.set(nodeId, newNode);

      if (!skipUpdate) {
        this.updateFlattenNodesAndSubject();
      }
      return nodeId;
    } else {
      // Handle adding a node without a parent (root item)
      const newNode = new TreeNode<T>({ ...data, isEditing: false }, this);
      this.rootItems.push(newNode); // Add the new root item
      this.nodeMap.set(nodeId, newNode);

      // only flatten if skipUpdate is false, this allows add many to flatten once
      if (!skipUpdate) {
        this.updateFlattenNodesAndSubject();
      }
      return nodeId;
    }
  }
  /**
   * Adds multiple nodes to the tree at once.
   * @param {T[]} items - An array of data objects for the nodes to add.
   */
  addMany(items: T[]): void {
    // Create a map of items by their ID for quick lookup
    const itemMap = new Map<string, T>();
    items.forEach((item) => itemMap.set(item.id, item));

    // Create a set to track visited nodes
    const visited = new Set<string>();
    const sortedItems: T[] = [];

    // Helper function to perform DFS and sort nodes
    const visit = (item: T) => {
      if (visited.has(item.id)) return;
      visited.add(item.id);

      if (item.parentId && itemMap.has(item.parentId)) {
        visit(itemMap.get(item.parentId)!);
      }

      sortedItems.push(item);
    };

    // Visit all items
    items.forEach((item) => visit(item));
    // Add sorted items to the tree
    sortedItems.forEach((item) => {
      this.add(item, true);
    });
    this.updateFlattenNodesAndSubject();
  }
  /**
   * Removes a node and all its descendants from the tree.
   * @param {string} nodeId - The ID of the node to remove.
   */
  remove(nodeId: string): void {
    const nodeToRemove = this.nodeMap.get(nodeId);
    if (!nodeToRemove) {
      throw new Error("Node to remove does not exist.");
    }

    const parentNodeId = nodeToRemove.data.parentId || null;
    const parentNode = parentNodeId ? this.nodeMap.get(parentNodeId) : null;

    if (parentNode) {
      parentNode.children.delete(nodeId);
    } else {
      // Remove from root items
      const index = this.rootItems.findIndex((item) => item.data.id === nodeId);
      if (index !== -1) {
        this.rootItems.splice(index, 1);
      }
    }

    // Recursively remove children
    this.removeNodeAndChildren(nodeToRemove);
    // Emit an event for the removed node
    this.treeEventsSubject.next({ type: "REMOVED_ITEM", payload: nodeToRemove.data });
    // this.treeEventsSubject.next({ type: "REMOVED_ITEM", payload: { nodeId: node.data.id } });
    this.updateFlattenNodesAndSubject();
  }

  /**
   * Moves a node to a new parent within the tree.
   * @param {string} nodeId - The ID of the node to move.
   * @param {string | null} [newParentId=null] - The ID of the node's new parent. If null, the node becomes a root node.
   */
  move(nodeId: string, newParentId?: string | null, isMultiSelect?: boolean): void {
    const nodeToMove = this.nodeMap.get(nodeId);
    if (!nodeToMove) {
      throw new Error(`Node with id ${nodeId} does not exist.`);
    }
    if (nodeToMove.data.parentId == newParentId) {
      return;
    }

    // Check if newParentId is a descendant of nodeId, which would create a circular reference
    let currentParent: TreeNode<T> | undefined = newParentId ? this.nodeMap.get(newParentId) : undefined;
    while (currentParent) {
      if (currentParent.data.id === nodeId) {
        console.log("bad drop");
        return;
      }
      currentParent = currentParent.data.parentId ? this.nodeMap.get(currentParent.data.parentId) : undefined;
    }

    // Remove node from current parent
    const currentParentId = nodeToMove.data.parentId;
    if (currentParentId) {
      const currentParent = this.nodeMap.get(currentParentId);
      currentParent?.children.delete(nodeId);
    } else {
      // Node is a root item, remove it from root items
      const index = this.rootItems.findIndex((item) => item.data.id === nodeId);
      if (index !== -1) {
        this.rootItems.splice(index, 1);
      }
    }

    // If newParentId is provided, set the new parent
    if (newParentId) {
      const newParent = this.nodeMap.get(newParentId);
      if (!newParent) {
        throw new Error(`New parent with id ${newParentId} does not exist.`);
      }
      nodeToMove.data.parentId = newParentId;
      newParent.children.set(nodeId, nodeToMove);
    } else {
      // If newParentId is not provided, make the node a root item
      nodeToMove.data.parentId = null;
      this.rootItems.push(nodeToMove);
    }

    // Update the nodeMap with the new parent
    this.nodeMap.set(nodeId, nodeToMove);
    if (!isMultiSelect) this.treeEventsSubject.next({ type: "MOVED_ITEM", payload: nodeToMove.data });
    // Update the flattened tree to reflect the changes
    this.updateFlattenNodesAndSubject();
  }

  /**
   * Moves all selected nodes to a new parent.
   * @param {string | null} parentId - The ID of the parent node to move the selected nodes under.
   */
  moveSelected(parentId: string | null) {
    this.selectedNodeIds.forEach((id) => {
      this.move(id, parentId, true);
    });
    const nodes = Array.from(this.selectedNodeIds)
      .map((id) => this.nodeMap.get(id)?.data)
      .filter((node): node is T => node !== undefined);
    this.treeEventsSubject.next({
      type: "MULTI_MOVED_ITEMS",
      payload: {
        parentId,
        items: nodes,
      },
    });
    this.clearSelectedNodes();
  }

  /**
   * Updates the name of a node.
   * @param {string | null} parentId - The ID of the parent node to move the selected nodes under.
   * @param {string} newName - The new name of the node.
   */
  setName(nodeId: string, newName: string, preventEdit?: boolean): void {
    const node = this.nodeMap.get(nodeId);
    if (node) {
      node.data.name = newName;
      if (!preventEdit) {
        this.toggleEdit(nodeId);
      }
      this.treeEventsSubject.next({ type: "RENAME_ITEM", payload: node.data });
      this.updateFlattenNodesAndSubject();
    } else {
      throw new Error(`Node with id ${nodeId} does not exist.`);
    }
  }
  /**
   * Updates the tree nodes based on the provided array of nodes.
   * @param {T[]} nodes - The array of nodes to update the tree with.
   */
  updateNodes(nodes: T[]): void {
    const nodeIds = new Set(nodes.map((node) => node.id));

    // Update existing nodes and add new nodes
    nodes.forEach((node) => {
      const existingNode = this.nodeMap.get(node.id);
      if (existingNode) {
        existingNode.data = {
          ...existingNode.data,
          ...node,
        };
        // Spread in all the new data, preserving only the tree-specific properties
      } else {
        this.add(node, true);
      }
    });

    // Remove nodes that are not in the provided array
    this.nodeMap.forEach((_, nodeId) => {
      if (!nodeIds.has(nodeId)) {
        this.remove(nodeId);
      }
    });

    // Update the flattened tree structure
    this.updateFlattenNodesAndSubject();
  }

  /**
   * Triggers a flattening of the tree nodes and emits the updated list to observers.
   * Typically called internally after modifications to the tree structure.
   */
  private updateFlattenNodesAndSubject() {
    // Sort the tree before flattening
    this.sortTree();

    // Flatten the tree
    const flattenedTree: FullData<T>[] = [];

    const traverseTree = (node: TreeNode<T>, depth: number) => {
      const isOpen = node.isOpen;

      flattenedTree.push({
        ...node.data,
        depth,
        isOpen,
        isSelected: node.isSelected,
        isEditing: node.isEditing,
        toggleEdit: node.toggleEdit,
        setName: node.setName,
        remove: node.remove,
        isLeaf: node.isLeaf,
      });

      if (isOpen) {
        node.children.forEach((childNode) => {
          traverseTree(childNode, depth + 1);
        });
      }
    };

    this.rootItems.forEach((rootItem) => {
      traverseTree(rootItem, 0);
    });

    this.flattenedTree = flattenedTree;
    this.flattenNodesSubject.next(this.flattenedTree);
  }
  /**
   * Recursively removes a node and its children from the tree.
   * @param {TreeNode<T>} node - The TreeNode to remove.
   */
  private removeNodeAndChildren(node: TreeNode<T>) {
    node.children.forEach((childNode) => {
      this.removeNodeAndChildren(childNode);
    });

    // Remove from nodeMap
    this.nodeMap.delete(node.data.id);

    // Remove from parent's children
    const parentNodeId = node.data.parentId || null;
    const parentNode = parentNodeId ? this.nodeMap.get(parentNodeId) : null;
    if (parentNode) {
      parentNode.children.delete(node.data.id);
    } else {
      // Remove from root items
      const index = this.rootItems.findIndex((item) => item.data.id === node.data.id);
      if (index !== -1) {
        this.rootItems.splice(index, 1);
      }
    }

    // Update flattened nodes and notify observers
    this.updateFlattenNodesAndSubject();
  }

  /**
   * Retrieves the current flattened representation of the tree.
   * @returns {FullData<T>[]} - The flattened tree data.
   */
  getFlattenedTree(): FullData<T>[] {
    return this.flattenedTree;
  }

  /**
   * Subscribes a function to tree events.
   *
   * @param {(event: TreeEvent<T>) => void} observer - The function to be called when a tree event occurs.
   * This function will be passed an object with a `type` property indicating the type of event
   * (e.g., 'move'), and a `payload` property containing the data of the node that was moved.
   *
   * @returns {Subscription} A Subscription object. You can call `unsubscribe` on this object to stop receiving events.
   */
  subscribeToEvents(observer: (event: TreeEvent<T>) => void): Subscription {
    return this.treeEventsSubject.subscribe(observer);
  }

  /**
   * Toggles the open state of a node in the tree, which affects its visibility in the flattened tree.
   * @param {string} nodeId - The ID of the node to toggle.
   */
  toggleOpen(nodeId: string): void {
    const node = this.nodeMap.get(nodeId);
    if (node && !node.isLeaf) {
      // Toggle the isOpen property
      node.isOpen = !node.isOpen;

      // Fetch items for the node if it's being opened and fetchItemsPromise is provided
      if (node.isOpen && this.fetchItemsPromise) {
        this.fetchItems(nodeId, node.data);
      } else if (node.isOpen && !this.fetchItemsPromise) {
        this.treeEventsSubject.next({ type: "FETCH_NEW_ITEMS", payload: node.data });
      }
      // Update the flattened tree to reflect the change
      this.updateFlattenNodesAndSubject();
    }
  }

  /**
   * Toggles the editing state of a node in the tree, which affects its visibility in the flattened tree.
   * @param {string} nodeId - The ID of the node to toggle.
   */
  toggleEdit(nodeId: string): void {
    const node = this.nodeMap.get(nodeId);
    if (node) {
      // Toggle the isEditing property
      node.isEditing = !node.isEditing;

      // Update the flattened tree to reflect the change
      this.updateFlattenNodesAndSubject();
    }
  }

  /**
   * Toggles the visibility of a loading placeholder as a child of the specified node.
   * This is typically used to indicate that data is being loaded.
   * @param {string} nodeId - The ID of the parent node.
   */
  toggleLoading(nodeId: string): void {
    const parentNode = this.nodeMap.get(nodeId);

    if (!parentNode) {
      throw new Error(`Parent node with id ${nodeId} does not exist.`);
    }

    // Check if loading node already exists
    const loadingNode = parentNode.children.get("temp-loading-node");
    if (loadingNode) {
      // Loading node exists, so remove it
      parentNode.children.delete("temp-loading-node");
    } else {
      // Create a new loading node
      const loadingData: T = {
        id: "temp-loading-node",
        name: "temp-loading-node",
        parentId: nodeId,
        isLeaf: true, // Assuming the loading node has no children
      } as T; // Cast because we're sure about the structure even though it's partial

      // Create a new map with the loading node as the first entry
      const newChildren = new Map<string, TreeNode<T>>([["temp-loading-node", new TreeNode<T>(loadingData, this)]]);

      // Insert the rest of the children after the loading node
      parentNode.children.forEach((child, key) => {
        newChildren.set(key, child);
      });

      // Set the parent's children to the new map
      parentNode.children = newChildren;
    }

    // Update the flattened tree and notify observers
    this.updateFlattenNodesAndSubject();
  }

  /**
   * Marks a node as selected in the tree.
   * @param {string} nodeId - The ID of the node to select.
   */
  selectNode(nodeId: string): void {
    const node = this.nodeMap.get(nodeId);
    if (node) {
      node.isSelected = true;
      this.selectedNodeIds.add(nodeId);
      // Emit changes or update state if needed
    }
  }

  /**
   * Deselects a node in the tree.
   * @param {string} nodeId - The ID of the node to deselect.
   */
  deselectNode(nodeId: string): void {
    const node = this.nodeMap.get(nodeId);
    if (node) {
      node.isSelected = false;
      this.selectedNodeIds.delete(nodeId);
      // Emit changes or update state if needed
    }
  }

  /**
   * Toggles the selection state of a node. If the node is selected, it will be deselected and vice versa.
   * @param {string} nodeId - The ID of the node to toggle.
   */
  toggleSelected(nodeId: string): void {
    if (this.selectedNodeIds.has(nodeId)) {
      this.deselectNode(nodeId);
    } else {
      this.selectNode(nodeId);
    }
    this.updateFlattenNodesAndSubject();
  }

  /**
   * Clears the selection state of all nodes in the tree.
   */
  clearSelectedNodes(): void {
    this.selectedNodeIds.forEach((nodeId) => {
      const node = this.nodeMap.get(nodeId);
      if (node) {
        node.isSelected = false;
      }
    });
    this.selectedNodeIds.clear();
    this.updateFlattenNodesAndSubject();
  }

  /**
   * Sorts the tree nodes based on a given sorting function.
   * The sorting function defines how the nodes should be compared for the sort.
   * @param {SortFunction<T>} sortFunction - The function to be used for sorting the nodes.
   */
  private sortTree() {
    if (this.sortFunction) {
      // Sort root items
      this.rootItems.sort(this.sortFunction);

      // Recursively sort children
      const sortChildren = (node: TreeNode<T>) => {
        const sortedChildren = Array.from(node.children.values()).sort(this.sortFunction!);
        node.children = new Map(sortedChildren.map((child) => [child.data.id, child]));

        // Sort each of the children's children
        sortedChildren.forEach(sortChildren);
      };

      this.rootItems.forEach(sortChildren);
    }
  }
}

export type TreeEvent<T extends BaseRowData> =
  | {
      type: "TREE_INITIALIZED";
      payload: Tree<T>;
    }
  | {
      type: "FETCHED_NEW_ITEMS";
      payload: {
        parentId: string | null;
        items: T[];
      };
    }
  | {
      type: "FETCH_ERROR";
      payload: {
        parentId: string | null;
        error: Error;
      };
    }
  | {
      type: "MOVED_ITEM";
      payload: T;
    }
  | {
      type: "MULTI_MOVED_ITEMS";
      payload: {
        parentId: string | null;
        items: T[];
      };
    }
  | { type: "REMOVED_ITEM"; payload: T }
  | { type: "RENAME_ITEM"; payload: T }
  | {
      type: "FETCH_NEW_ITEMS";
      payload: T;
    };
