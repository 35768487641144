export type {
  Cell,
  Component,
  ComponentType,
  Dashboard,
  DashboardCopyToProfile,
  DashboardNew,
  DashboardPublished,
  DashboardTab,
  DashboardUpdate,
  Styles,
  DashboardDiscovered,
  DashboardLikes,
} from "./dashboard";
export {
  schema,
  discoverSchema,
  cellContainerSchema,
  newSchema,
  updateSchema,
  publishedSchema,
  addVizSchema,
  copyToProfileSchema,
  dashboardLikesSchema,
  // V3 functions
  createDashboardNew,
} from "./dashboard";

import * as cellContent from "./cell-content";
import * as cellLayout from "./cell-layout";
import * as cellStyle from "./cell-style";
import * as cells from "./cells";
import * as dashboardConfig from "./dashboard-config";
import * as dashboardLikes from "./dashboard-likes";
import * as dashboardParameters from "./dashboard-parameters-new";
import * as dashboardParams from "./dashboard-params";
import * as dashboardTree from "./dashboard-tree";

export const dashboard = {
  ...cells,
  ...cellContent,
  ...cellStyle,
  ...cellLayout,
  ...dashboardConfig,
  ...dashboardParams,
  ...dashboardLikes,
  ...dashboardParameters,
  ...dashboardTree,
};

export type {
  CellVariant,
  CellLayout,
  CellInfo,
  VariantContentMap,
  ContentByVariant,
  CellContent,
  HeaderCellContent,
  ImageCellContent,
  VariantStylesMap,
  StylesByVariant,
  CellStyle,
  HeaderCellStyles,
  ImageCellStyles,
  TextCellStyles,
  BaseCellStyles,
  LayoutCellStyles,
  TextCellContent,
  VisualizationCellContent,
  LegacyTableCellContent,
  DashboardView,
} from "./configSchema";

export type { DashboardParams, LayoutParams } from "./dashboard-params";
export type { DashboardEphemeralParamData } from "./dashboard-parameters-new";
export type { DashboardTreeNodeData } from "./dashboard-tree";
