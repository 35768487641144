import { Dashboard } from "../dashboard";

export type DashboardCreated = {
  type: "DASHBOARD.CREATED";
  version: 2;
  payload: Dashboard;
};

export type DashboardUpdated = {
  type: "DASHBOARD.UPDATED";
  version: 2;
  payload: Dashboard;
};

export type DashboardUpdatedRealtime = {
  type: "DASHBOARD.UPDATED.REALTIME";
  payload: {
    timestamp: number;
  };
};

export type DashboardUpdatedPersistSuccess = {
  type: "DASHBOARD.UPDATED.PERSIST.SUCCESS";
  payload: Dashboard;
};

export type DashboardUpdatedPersistFailure = {
  type: "DASHBOARD.UPDATED.PERSIST.FAILURE";
  payload: {
    error: string;
    statusCode: number;
    id: string;
  };
};

export type DashboardDeleted = {
  type: "DASHBOARD.DELETED";
  version: 2;
  payload: {
    id: string;
  };
};

const created = (payload: Dashboard) => {
  return {
    type: "DASHBOARD.CREATED",
    version: 2,
    payload,
  } satisfies DashboardCreated;
};

const updated = (payload: Dashboard) => {
  return {
    type: "DASHBOARD.UPDATED",
    version: 2,
    payload,
  } satisfies DashboardUpdated;
};

const updatedRealtime = (payload: { timestamp: number }) => {
  return {
    type: "DASHBOARD.UPDATED.REALTIME",
    payload,
  } satisfies DashboardUpdatedRealtime;
};

const updatedPersistSuccess = (payload: Dashboard) => {
  return {
    type: "DASHBOARD.UPDATED.PERSIST.SUCCESS",
    payload,
  } satisfies DashboardUpdatedPersistSuccess;
};

const updatedPersistFailure = (payload: { error: string; statusCode: number; id: string }) => {
  return {
    type: "DASHBOARD.UPDATED.PERSIST.FAILURE",
    payload,
  } satisfies DashboardUpdatedPersistFailure;
};

const deleted = (payload: { id: string }) => {
  return {
    type: "DASHBOARD.DELETED",
    version: 2,
    payload,
  } satisfies DashboardDeleted;
};

export const dashboards = {
  created,
  updated,
  updatedRealtime,
  updatedPersistSuccess,
  updatedPersistFailure,
  deleted,
};

export type DashboardEvent =
  | DashboardCreated
  | DashboardUpdated
  | DashboardDeleted
  | DashboardUpdatedRealtime
  | DashboardUpdatedPersistSuccess
  | DashboardUpdatedPersistFailure;
