import { nanoid } from "nanoid";
import { Dashboard } from "../dashboard/dashboard";
import {
  addCellContent,
  createCellContent,
  findCellContentById,
  isCellContentVariant,
  removeCellContent,
} from "./cell-content";
import { addCellLayout, createCellLayout, removeCellLayout, updateCellLayout } from "./cell-layout";
import { addCellStyle, createCellStyle, removeCellStyles } from "./cell-style";
import { CellContent, CellInfo, CellLayout, CellStyle, CellVariant, DashboardView } from "./configSchema";
import { updateDashboardConfig } from "./dashboard-config";

export const addDashboardCell = ({
  db,
  variant,
  parentId = "root",
  layout = {},
  content,
  style,
  id,
  ensureTopPosition = false,
}: {
  db: Dashboard;
  variant: CellVariant;
  parentId?: string;
  layout?: Partial<CellLayout>;
  content?: Partial<CellContent>;
  style?: Partial<CellStyle>;
  id?: string;
  ensureTopPosition?: boolean;
}): Dashboard => {
  // Create a new unique ID for the cell and associated elements
  const newId = id ? id : `${variant}-${nanoid(4)}`;

  // Create and add the new cell
  const newCell = createCell({ id: newId, variant, parentId });
  let updatedDb = addCell(db, newCell);

  // Add cell id to parentCell
  updatedDb = findAndAddCellToParentCell({ parentId, cellId: newId, db: updatedDb });

  const newCellLayout = createCellLayout({ id: newId, layout, variant, db: updatedDb, parentId });
  updatedDb = addCellLayout(updatedDb, newCellLayout);

  if (ensureTopPosition) {
    updatedDb = ensureTopPositionAndMoveSiblings(updatedDb, parentId, newId, newCellLayout.h);
  }

  // Create and add content for the cell
  const newCellContent = createCellContent({ id: newId, variant: variant as CellContent["variant"], content });
  updatedDb = addCellContent(updatedDb, newCellContent as CellContent);

  // Create and add a config for the cell
  const newCellConfig = createCellStyle({ id: newId, variant: variant as CellStyle["variant"], style });
  updatedDb = addCellStyle(updatedDb, newCellConfig);

  return updatedDb;
};

export const removeDashboardCell = ({ db, cellId }: { db: Dashboard; cellId: string }): Dashboard => {
  db = removeCellFromParent(db, cellId);
  db = removeCellLayout(db, cellId);
  db = removeCellContent(db, cellId);
  db = removeCellStyles(db, cellId);
  db = removeCell(db, cellId);
  return db;
};

export const createCell = ({
  id,
  variant,
  parentId,
}: {
  id: string;
  variant: CellInfo["variant"];
  parentId?: string;
}) => {
  const newCell: CellInfo = {
    id,
    variant,
    childCellIds: [],
    parentCellId: parentId ?? null,
  };

  return newCell;
};

export const findCellById = (db: Dashboard, id: string, dashboardView: DashboardView) => {
  if (dashboardView === "published") {
    return db.publishedConfig?.cells[id];
  }
  return db.draftConfig.cells[id];
};

export const addCell = (db: Dashboard, cell: CellInfo) => {
  const newCells = {
    ...db.draftConfig.cells,
    [cell.id]: cell,
  };
  return updateDashboardConfig(db, "cells", newCells);
};

export const updateCell = (db: Dashboard, cell: CellInfo) => {
  const newCells = {
    ...db.draftConfig.cells,
    [cell.id]: cell,
  };
  return updateDashboardConfig(db, "cells", newCells);
};

export const removeCell = (db: Dashboard, cellId: string): Dashboard => {
  const { cells } = db.draftConfig;
  if (!findCellById(db, cellId, "draft")) {
    return db; // Cell does not exist, nothing to do
  }

  // Create a new cells object without the specified cellId
  const newCells = { ...cells };
  delete newCells[cellId];

  // Update the dashboard configuration
  return updateDashboardConfig(db, "cells", newCells);
};

export const findAndAddCellToParentCell = ({
  parentId = "root",
  cellId,
  db,
}: {
  parentId?: string;
  cellId: string;
  db: Dashboard;
}) => {
  const parentCell = findCellById(db, parentId, "draft");
  if (!parentCell) {
    throw new Error("Parent cell not found");
  }
  const childIds = parentCell?.childCellIds ?? [];
  const newChildIds = [...childIds, cellId];
  const newParentCell = {
    ...parentCell,
    childCellIds: newChildIds,
  };

  return updateCell(db, newParentCell);
};

export const removeCellFromParent = (db: Dashboard, cellId: string): Dashboard => {
  const cell = findCellById(db, cellId, "draft");
  if (!cell || !cell.parentCellId) {
    return db; // Cell does not exist or has no parent, nothing to do
  }

  const parentCell = findCellById(db, cell.parentCellId, "draft");
  if (!parentCell) {
    return db; // Parent cell does not exist, nothing to do
  }

  // Filter out the cellId from the parent's childCellIds
  const updatedParentCell: CellInfo = {
    ...parentCell,
    childCellIds: parentCell.childCellIds?.filter((id) => id !== cellId) || [],
    // if the cell is a paramCell, add paramsRemoved to the parentCell
    paramsRemoved: cell.variant === "params" ? true : parentCell.paramsRemoved,
  };

  // Update the parent cell in the database
  return updateCell(db, updatedParentCell);
};

export const setCell = (db: Dashboard, cellId: string, newCell: Partial<CellInfo>) => {
  const cell = findCellById(db, cellId, "draft");
  if (!cell) {
    return db; // Cell does not exist, nothing to do
  }

  const newCellData: CellInfo = { ...cell, ...newCell };

  return updateCell(db, newCellData);
};

export const toggleOpen = (db: Dashboard, cellId: string): Dashboard => {
  const cell = findCellById(db, cellId, "draft");
  if (!cell) {
    return db; // Cell does not exist, nothing to do
  }

  // Toggle the isOpen state of the cell
  const isOpenToggled = !cell.isOpen;
  const newCellData: CellInfo = { ...cell, isOpen: isOpenToggled };

  // Update the cell in the dashboard
  const updatedDb = updateCell(db, newCellData);

  // If isOpen is toggled to true and the cell has a parent, recursively set all parents' isOpen to true
  if (isOpenToggled && cell.parentCellId) {
    return setOpenTrueForParents(updatedDb, cell.parentCellId);
  }

  return updatedDb;
};

/**
 * Recursive function to set isOpen to true for a cell and all its parent cells.
 */
function setOpenTrueForParents(db: Dashboard, cellId: string): Dashboard {
  const cell = findCellById(db, cellId, "draft");
  if (!cell) {
    return db; // Cell does not exist, nothing to do
  }

  // Set isOpen to true if it's not already
  if (!cell.isOpen) {
    const newCellData: CellInfo = { ...cell, isOpen: true };
    const updatedDb = updateCell(db, newCellData);

    // Recurse for the parent if there is one
    if (cell.parentCellId) {
      return setOpenTrueForParents(updatedDb, cell.parentCellId);
    }
    return updatedDb;
  }
  return db;
}

export const getDashboardQueryIds = (db: Dashboard) => {
  const { contents } = db.draftConfig;

  const queryIds = Object.values(contents)
    .map((cell) => {
      if (cell?.variant !== "visualization") return undefined;
      return cell?.queryId;
    })
    .filter((id): id is string => id !== undefined);

  return [...new Set(queryIds)];
};

export const findChildQueryIds = (db: Dashboard, parentId: string, dashboardView: DashboardView) => {
  const parentCell = findCellById(db, parentId, dashboardView);
  if (!parentCell) {
    return [];
  }
  const childIds = parentCell?.childCellIds;
  if (!childIds) {
    return [];
  }
  const childQueryIds = childIds.reduce<string[]>((acc, id) => {
    const cellContent = findCellContentById(db, id, dashboardView);
    if (cellContent && isCellContentVariant(cellContent, "visualization")) {
      acc.push(cellContent.queryId);
    }
    return acc;
  }, []);

  return [...new Set(childQueryIds)];
};

/**
 * Recursively finds all unique query IDs from visualization cells that are descendants of a given parent cell.
 * The search is performed only if there are no "params" cells among the child cell IDs.
 *
 * @param {Dashboard} db - The dashboard object containing the cells.
 * @param {string} parentId - The ID of the parent cell to start the search from.
 * @param {DashboardView} dashboardView - The view of the dashboard ("draft" or "published").
 * @returns {string[]} - An array of unique query IDs from visualization cells.
 */
export const findDeepChildQueryIds = (db: Dashboard, parentId: string, dashboardView: DashboardView): string[] => {
  // Find the parent cell by its ID
  const parentCell = findCellById(db, parentId, dashboardView);
  if (!parentCell) {
    return [];
  }

  // Get the child cell IDs of the parent cell
  const childIds = parentCell?.childCellIds ?? [];

  const childQueryIds = childIds.reduce<string[]>((acc, id) => {
    const cellContent = findCellContentById(db, id, dashboardView);
    if (cellContent && isCellContentVariant(cellContent, "visualization")) {
      acc.push(cellContent.queryId);
    }

    // Recursively check for child cells
    const childCell = findCellById(db, id, dashboardView);
    if (childCell?.childCellIds) {
      // Check for params cells only in nested layouts
      const hasParamsCell = childCell.childCellIds.some((childId) => {
        const nestedCell = findCellById(db, childId, dashboardView);
        return nestedCell?.variant === "params";
      });

      if (!hasParamsCell) {
        acc.push(...findDeepChildQueryIds(db, id, dashboardView));
      }
    }

    return acc;
  }, []);

  // Return a unique array of query IDs
  return [...new Set(childQueryIds)];
};

export const findChildParamCellId = (db: Dashboard, parentId: string, dashboardView: DashboardView) => {
  const childIds = findCellById(db, parentId, dashboardView)?.childCellIds;
  const paramId = childIds?.find((id) => id.includes("params"));
  return paramId;
};

export const moveDashboardCell = ({
  db,
  cellId,
  newParentId,
}: {
  db: Dashboard;
  cellId: string;
  newParentId: string;
}): Dashboard => {
  // Find the cell to be moved
  const cellToMove = findCellById(db, cellId, "draft");
  if (!cellToMove) {
    throw new Error("Cell to move not found");
  }

  // If the cell is already under the new parent, do nothing
  if (cellToMove.parentCellId === newParentId) {
    return db;
  }

  // Remove the cell from its current parent's childCellIds
  if (cellToMove.parentCellId) {
    db = removeCellFromParent(db, cellId);
  }

  // Update the parentCellId of the cell to move
  const updatedCell: CellInfo = {
    ...cellToMove,
    parentCellId: newParentId,
  };

  // Add the cell to the new parent's childCellIds
  db = findAndAddCellToParentCell({
    parentId: newParentId,
    cellId: cellId,
    db: db,
  });

  // Update the cell with the new parent information
  return updateCell(db, updatedCell);
};

export const getTabsPanelCells = (db: Dashboard) => {
  const draftCells = Object.values(db.draftConfig.cells);
  const publishedCells = Object.values(db.publishedConfig?.cells ?? {});
  return {
    draft: draftCells.filter((c): c is NonNullable<typeof c> => c !== undefined && c.variant === "tabs-panel"),
    published: publishedCells.filter((c): c is NonNullable<typeof c> => c !== undefined && c.variant === "tabs-panel"),
  };
};

/**
 * Finds the closest param cell ID for a given cell ID by traversing up the cell hierarchy.
 * @param db The Dashboard object
 * @param cellId The ID of the cell to start the search from
 * @param dashboardView The view of the dashboard ("draft" or "published")
 * @returns The ID of the closest param cell, or undefined if none is found
 */
export const findClosestParamCellId = (
  db: Dashboard | undefined,
  cellId: string,
  dashboardView: DashboardView,
): string | undefined => {
  if (!db) return undefined;
  const cell = findCellById(db, cellId, dashboardView);
  if (!cell) return undefined;

  if (cell.variant === "params") return cellId;

  let currentParentId = cell.parentCellId;

  while (currentParentId) {
    const parentCell = findCellById(db, currentParentId, dashboardView);
    if (!parentCell) return undefined;

    const paramCellId = parentCell.childCellIds?.find((id) => {
      const childCell = findCellById(db, id, dashboardView);
      return childCell?.variant === "params";
    });

    if (paramCellId) return paramCellId;

    // If we've reached the root, check it and then end recursion
    if (currentParentId === "root") {
      const rootCell = findCellById(db, "root", dashboardView);
      if (rootCell?.childCellIds?.some((id) => id.includes("params"))) {
        return rootCell.childCellIds.find((id) => id.includes("params"));
      }
      return undefined;
    }

    currentParentId = parentCell.parentCellId;
  }

  return undefined;
};

export const ensureTopPositionAndMoveSiblings = (
  db: Dashboard,
  parentId: string,
  newCellId: string,
  newCellHeight: number,
): Dashboard => {
  const parentCell = findCellById(db, parentId, "draft");
  if (!parentCell) return db;

  let updatedDb = db;

  const siblingLayouts =
    parentCell.childCellIds
      ?.filter((id) => id !== newCellId) // Exclude the new cell
      .map((childId) => updatedDb.draftConfig.layouts[childId])
      .filter((layout): layout is CellLayout => layout !== undefined) ?? [];

  // Adjust positions of existing cells that are not static
  siblingLayouts.forEach((siblingLayout) => {
    if (!siblingLayout.static) {
      updatedDb = updateCellLayout(updatedDb, siblingLayout.id, { y: siblingLayout.y + newCellHeight });
    }
  });

  // Ensure the new cell is at the top
  updatedDb = updateCellLayout(updatedDb, newCellId, { x: 0, y: 0 });

  return updatedDb;
};

export const isAncestor = (db: Dashboard, descendantId: string, potentialAncestorId: string): boolean => {
  const cell = findCellById(db, descendantId, "draft");
  if (!cell) return false;

  let currentCellId = cell.parentCellId;
  while (currentCellId) {
    if (currentCellId === potentialAncestorId) {
      return true;
    }
    const parentCell = findCellById(db, currentCellId, "draft");
    if (!parentCell) break;
    currentCellId = parentCell.parentCellId;
  }
  return false;
};
