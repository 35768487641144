import { visualization } from "@fscrypto/domain";
import { Client } from "@fscrypto/http";
import { Observable, filter, firstValueFrom, from, map, shareReplay } from "rxjs";
import { Query, QueryFactory, queryFactory } from "../../query/state/query";
import { VisualizationClient } from "../../visualization/data/visualization-client";
import {
  V2VisualizationEntity,
  V2VisualizationFactory,
  v2VisualizationFactory,
} from "../../visualization/state/v2-visualization";
import { Visualization, VisualizationFactory, visualizationFactory } from "../../visualization/state/visualization";

class DashboardDataManager {
  private activeFetches: Map<string, Observable<Query>> = new Map();
  private activeVisFetches: Map<string, Observable<Visualization | V2VisualizationEntity>> = new Map();

  constructor(
    private queryFactory: QueryFactory,
    private visFactory: VisualizationFactory,
    private vis2Factory: V2VisualizationFactory,
    private visClient: VisualizationClient,
  ) {}

  getQueryData(queryId: string): Observable<Query> {
    if (!this.activeFetches.has(queryId)) {
      const observable = from(this.queryFactory.getById(queryId, "successful")).pipe(
        shareReplay(1), // This will cache the last emitted value and share it with subsequent subscribers
      );
      observable.subscribe();
      this.activeFetches.set(queryId, observable);
    }
    return this.activeFetches.get(queryId)!;
  }

  getVisData(visualizationId: string): Observable<Visualization | V2VisualizationEntity> {
    if (!this.activeVisFetches.has(visualizationId)) {
      const observable = from(this.visClient.findAnyVersion(visualizationId)).pipe(
        filter(Boolean),
        map((v) => {
          if (v.version === "3") {
            return this.visFactory.from(v as visualization.v3.Visualization);
          }
          return this.vis2Factory.from(v as visualization.Visualization);
        }),
        shareReplay(1), // This will cache the last emitted value and share it with subsequent subscribers
      );
      observable.subscribe();
      this.activeVisFetches.set(visualizationId, observable);
    }
    return this.activeVisFetches.get(visualizationId)!;
  }
  getMultipleQueryData(queryIds: string[]): Observable<Query[]> {
    return from(Promise.all(queryIds.map((id) => firstValueFrom(this.getQueryData(id))))).pipe(shareReplay(1));
  }
}

export const dashboardDataManager = new DashboardDataManager(
  queryFactory,
  visualizationFactory,
  v2VisualizationFactory,
  new VisualizationClient(new Client()),
);
